<template>
  <v-main class="ibx-item-card-standalone">
    <div v-if="!hasId && !hasError">Please specifiy an itemId or itemRevId</div>
    <div v-else-if="hasError" class="error--text ml-2 mt-2">
      <strong>Error: {{ errorMessage }}</strong>
    </div>
    <div v-else>
      <div v-if="controls" class="ibx-item-card-standalone__control">
        <a class="ibx-item-card-standalone__togglePassage" @click="showPassage = !showPassage">{{
          passageControlLabel
        }}</a>
      </div>
      <ibx-passage-card
        v-if="!fetching && hasPassage && showPassage"
        :passage="passage"
        view="expand"
        :actions="[]"
        :showItemCount="false"
        heightAuto
        noFooter
      />
      <div :class="itemWrapperClass">
        <item-card
          v-if="!fetching"
          :itemData="item"
          :preview="preview"
          context="provide"
          lazy
          :lazyLoadTrigger="true"
          view="expand"
        />
      </div>
    </div>
  </v-main>
</template>

<script>
import { ItemPlayerPlugin } from '@illuminateeducation/illuminate-components-assessments'
import { mapActions, mapGetters } from 'vuex'
import IBXService from '@services/ibx/IBXService'
import ItemCard from '@components/itemCardStandalone/ItemCard'
import '@icons'

export default {
  name: 'ItemCardView',

  components: {
    ItemCard,
    'ibx-passage-card': () => import('@components/ibx/_IbxPassageCard'),
  },

  data: () => ({
    item: null,
    passage: null,
    fetching: true,
    hasError: false,
    errorMessage: 'There was an error with your request',
    controls: false,
    showPassage: false,
    preview: true,
  }),

  computed: {
    ...mapGetters('auth', {
      authPieToken: 'pieToken',
    }),
    hasId() {
      return Boolean(this.getItemId || this.getItemRevId)
    },
    getItemId() {
      return this.$route.query.itemId
    },
    getItemRevId() {
      return this.$route.query.itemRevId
    },
    hasPassage() {
      return Boolean(this.passage)
    },
    itemWrapperClass() {
      const classes = ['ibx-item-card-standalone__item-wrapper']
      if (this.showPassage) classes.push('ibx-item-card-standalone__item-wrapper--show-passage')
      return classes
    },
    passageControlLabel() {
      return this.showPassage ? 'Hide Passage' : 'Show Passage'
    },
  },

  methods: {
    ...mapActions('auth', {
      authInit: 'init',
    }),
    getParam(param) {
      return this.$route.query[param]
    },
  },

  created() {
    if (this.hasId) {
      this.authInit()
        .then(() => IBXService.itemRevisions(this.getItemRevId))
        .then(({ item }) => {
          this.item = item
          this.passage = this.item.passage
          this.controls = this.hasPassage
        })
        .catch((e) => {
          this.hasError = true
          console.warn(e)
        })
        .finally(() => (this.fetching = false))
    }
  },
}
</script>

<style lang="scss">
@import '@/styles/main';
.ibx-item-card-standalone {
  &__item-wrapper--show-passage {
    padding: 10px;
    background: $N20;
  }

  &__control {
    margin: 10px 0px 10px 0px;
  }
  &__togglePassage {
    padding: 20px;
    cursor: pointer;
  }
}
</style>
